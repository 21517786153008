import model from './model';
import { DispatchModalController } from './dispatchModalController';
import type { DispatchModalProps } from '../../types/widgetsProps';
import type { IWixWindow } from '@wix/yoshi-flow-editor';
import { registerToUrlChange } from 'root/utils/navigationUtils';
import type { IDispatchModalController } from './types';
import { dispatchState } from 'root/states/DispatchState';
import { DispatchModalStore } from 'root/states/DispatchModalStore';
import { FulfillmentsClient } from 'root/api/fulfillmentsClient';
import { OperationsClient } from 'root/api/operationClient';
import { initDispatchState } from 'root/states/initDispatchState';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { SPECS } from 'root/appConsts/experiments';
import { getPageOperationId } from 'root/utils/pageOperationUtils';
import { state as rootState } from '../../states/RootState';
import { runInAction } from 'mobx';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const { controllerConfig } = flowAPI;
  const { window } = controllerConfig.wixCodeApi;
  const lightboxContext = window.lightbox.getContext() || {};
  const {
    data: props,
    onModalOpen,
    closeModal,
  }: {
    data?: DispatchModalProps;
    onModalOpen: () => void;
    closeModal: (window: IWixWindow, data?: unknown) => void;
  } = lightboxContext;
  const {
    dispatchState: _dispatchState,
    operation,
    biReporterService,
    onSave,
    fedopsLogger,
  } = props ?? {};
  let dispatchModalController: IDispatchModalController;

  runInAction(() => {
    rootState.operation = operation;
  });

  let initStorePromise = Promise.resolve();

  const { isEditor, isPreview, isViewer } = flowAPI.environment;
  const { experiments, formatAddress } = flowAPI;

  const initDispatchStateInEditor = async () => {
    const { reportError, httpClient, errorMonitor } = flowAPI;
    const pageOperationIdPromise = experiments.enabled(SPECS.multiPages)
      ? getPageOperationId(controllerConfig.wixCodeApi.site, errorMonitor)
      : undefined;
    const fetchOperation = () =>
      new OperationsClient(httpClient).getOperation(pageOperationIdPromise);
    const operationData = await fetchOperation();
    runInAction(() => {
      rootState.operation = operationData;
    });
    const fulfillmentClient = new FulfillmentsClient(httpClient, operationData!.id);
    const timezone = controllerConfig.wixCodeApi.site.timezone || DEFAULT_TIMEZONE;

    const state = await initDispatchState(
      fulfillmentClient,
      Promise.resolve(operationData!),
      timezone,
      rootState.CartService?.getCurrentCart(),
      undefined,
      fedopsLogger,
      reportError
    );

    dispatchState.init(state);
    dispatchState.setIsLoading(false);
    const store = new DispatchModalStore(operationData!, fulfillmentClient);
    initStorePromise = store.initTimeAndDates();
    dispatchModalController = new DispatchModalController(
      $bindAll,
      $w,
      flowAPI,
      window,
      store,
      operationData!,
      () => {},
      biReporterService,
      !isEditor && !isPreview && isViewer
    );
  };

  if (operation && _dispatchState && onSave) {
    dispatchState.init(_dispatchState);
    const fulfillmentClient = new FulfillmentsClient(flowAPI.httpClient, operation.id);
    const store = new DispatchModalStore(
      operation,
      fulfillmentClient,
      formatAddress,
      flowAPI,
      fedopsLogger
    );
    initStorePromise = store.initTimeAndDates();
    dispatchModalController = new DispatchModalController(
      $bindAll,
      $w,
      flowAPI,
      window,
      store,
      operation,
      onSave,
      biReporterService,
      !isEditor && !isPreview && isViewer
    );
  }

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { wixCodeApi } = controllerConfig;
      registerToUrlChange(wixCodeApi, closeModal, window);
      if ((isEditor || isPreview) && !operation && !_dispatchState) {
        await initDispatchStateInEditor();
      }
      await initStorePromise;
      dispatchModalController.init(onModalOpen, closeModal);
    },
    exports: {},
  };
});
